import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CourseInfoCard } from "../components/Card/CourseInfoCard";
import LoadingBar from "../components/Layout/LoadingBar";
import { useCourses } from "../hooks/useCourses";
import { useTests } from "../hooks/useTests";
import { getTestPercentage } from "../utils/Percentage";
import { getRoutePath } from "../utils/portalPages";
import Modal from "../components/Modal/Modal";
import { useEffect, useState } from "react";
import { useUser } from "../hooks/useUser";

const classes = "border border-gray-light shadow-2xl py-6 px-9 flex flex-col w-72 group hover:shadow-neutral-400 hover:border-green-700 rounded-md";

export function Course() {
  const { t } = useTranslation();
  const { selectedCourse } = useCourses();
  const { setUserMetadata } = useUser();
  const { isReviewTestEnabled, isLoadingTestInfo, infoExam, infoSubject, courseProgress } = useTests();
  const [showModalReviewTestNotice, setModalReviewTestNotice] = useState<boolean>(false);

  useEffect(() => {
    setUserMetadata({ currentPage: `Curso: ${selectedCourse?.title}`, currentLesson: null, currentTest: null });
  }, [selectedCourse]);

  if (isLoadingTestInfo) {
    return <LoadingBar text={t("Courses.loading-courses")} />;
  }

  return (
    <>
      <div className="bg-white flex flex-col justify-start w-full p-6 md:py-10 md:px-14 shadow-lg">
        <>
          {/*
        <p className="text-lg font-light mb-8 text-left">{t("Course.progress")}</p>
        {courseProgress ? (
          <div className="w-full text-center mt-auto">
            <div className="overflow-hidden h-5 text-xs flex bg-gray-200 rounded-full w-full">
              <div
                style={{
                  width: `${courseProgress}%`,
                }}
                className={classNames(
                  "shadow-none flex flex-col text-center whitespace-nowrap justify-center",
                  courseProgress === 100 ? "bg-green-torcal/70" : "bg-orange-torcal/70"
                )}
              >
                {courseProgress} %
              </div>
            </div>
          </div>
        ) : null}
         */}
        </>

        <div className="my-8 flex flex-wrap gap-6 justify-center md:justify-start">
          <Link
            to={
              selectedCourse?.hasLessons
                ? getRoutePath("lessons", {
                    courseId: `${selectedCourse?.id}`,
                  })
                : ""
            }
            className={classNames(classes, {
              "hover:cursor-not-allowed text-gray-300": !selectedCourse?.hasLessons,
            })}
          >
            <CourseInfoCard
              imgSrc="/statics/lecciones.png"
              title={t("Courses.lessons")}
              subTitle={t("Courses.go-to-lessons")}
              disabled={!selectedCourse?.hasLessons}
            />
          </Link>
          <Link
            to={getRoutePath("tests.subject", {
              courseId: `${selectedCourse?.id}`,
            })}
            className={classes}
          >
            <CourseInfoCard
              imgSrc="/statics/test_de_temas.png"
              title={t("Courses.test-subject")}
              subTitle={t("Courses.go-to-test-subject")}
              info={infoSubject}
              progress={infoSubject ? getTestPercentage(infoSubject) : undefined}
            />
          </Link>
          <Link
            to={getRoutePath("tests.exam", {
              courseId: `${selectedCourse?.id}`,
            })}
            className={classes}
          >
            <CourseInfoCard
              imgSrc="/statics/test_de_examen.png"
              title={t("Courses.test-exam")}
              subTitle={t("Courses.go-to-test-exam")}
              info={infoExam}
              progress={infoExam ? getTestPercentage(infoExam) : undefined}
            />
          </Link>
          <Link
            to={
              isReviewTestEnabled
                ? getRoutePath("tests.revision", {
                    courseId: `${selectedCourse?.id}`,
                  })
                : ""
            }
            onClick={() => (!isReviewTestEnabled ? setModalReviewTestNotice(true) : undefined)}
            className={classes}
          >
            <CourseInfoCard
              imgSrc="/statics/test_de_repaso.png"
              title={t("Courses.test-review")}
              subTitle={t("Courses.go-to-test-review")}
              disabled={!isReviewTestEnabled}
            />
          </Link>
        </div>
      </div>
      <Modal isOpen={showModalReviewTestNotice} closeModal={() => setModalReviewTestNotice(false)} title={t("notice")}>
        <div className="my-5 flex flex-col">{t("Courses.test-review-disabled-msn")}</div>
      </Modal>
    </>
  );
}
